import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  isNullOrUndefined,
  map_industry,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import FBOXService from '../services/FBOXService';
import TDTicker from "../components/TDTicker";
import TDTrend from "../components/TDTrend";
import {TableHeadBase} from "./Summary";
import TDTickerVol from "../components/TDTickerVol";
import TDSignal from "../components/TDSignal";
import TDPrice from "../components/TDPrice";

function Follow() {
  const title = 'Follow';
  const [isLoading, setLoad] = useState(true);
  const [ownRows, setOwnRows] = useState([]);
  const [trackingRows, setTrackingRows] = useState([]);

  useEffect(() => {
    async function fetch_follow() {
      const MY_CATALOG = JSON.parse(window.sessionStorage.getItem('my_catalog'))
      const own_tickers = MY_CATALOG['own']
      let own = []
      let tracking = []
      if (!isNullOrUndefined(own_tickers)) {
        const own_response = await FBOXService.fetch_tickers_detail(own_tickers)
        own = own_response.map((item) => JSON.parse(item))
      }
      const tracking_tickers = MY_CATALOG['tracking'].reduce((acc, obj) => {
        acc[obj.ticker] = obj;
        return acc;
      }, {});
      if (!isNullOrUndefined(Object.keys(tracking_tickers))) {
        const tracking_response = await FBOXService.fetch_tickers_detail(Object.keys(tracking_tickers))
        tracking = tracking_response.map((item) => {
          const ticker = JSON.parse(item)
          ticker.follow_note = tracking_tickers[ticker.ticker].follow_note
          return ticker
        })
      }
      own.sort((a, b) => {
        return b.fs_rating - a.fs_rating
      })
      setOwnRows(own)
      tracking.sort((a, b) => {
        return b.fs_rating - a.fs_rating
      })
      setTrackingRows(tracking)
      setLoad(false)
    }
    fetch_follow()
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>Own</caption>
                <TableHeadBase/>
                <tbody>
                {
                  ownRows && ownRows.map((ticker, i) => {
                    return (
                      <tr key={i}>
                        {/*<TDTicker ticker={ticker} highlight_own={false}/>*/}
                        {/*<td className="text-start">{map_industry(ticker.industry)}</td>*/}
                        {/*<td className="text-end">{format_date(ticker.date)}</td>*/}
                        {/*<td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)} ({format_float(ticker.pricePercent * 100)} %)</td>*/}
                        {/*<TDTrend ticker={ticker} />*/}
                        {/*<td className="text-center">{ ticker.base }</td>*/}
                        {/*<td className="text-center">{ ticker.signal }</td>*/}
                        {/*<td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>*/}
                        {/*<td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>*/}
                        {/*<td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{format_float(ticker.giaodichnn)}</td>*/}
                        <TDTickerVol ticker={ticker} highlight_own={false}/>
                        <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
                        <TDPrice ticker={ticker}/>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>Follow</caption>
                <thead>
                <tr>
                  <th className="th-ticker">ticker</th>
                  <th className="th-industry">industry</th>
                  <th>date</th>
                  <th className="th-priceFlat">priceFlat</th>
                  <th>trend</th>
                  <th className="th-base">base</th>
                  <th className="th-signal">signal</th>
                  <th>priceAction</th>
                  <th>fs_rating</th>
                  <th>giaodichnn</th>
                  <th>note_________________________________________________</th>
                </tr>
                </thead>
                <tbody>
                {
                  trackingRows && trackingRows.map((ticker, i) => {
                    return (
                      <tr key={i}>
                        <TDTicker ticker={ticker} />
                        <td className="text-start">{map_industry(ticker.industry)}</td>
                        <td className="text-end">{format_date(ticker.date)}</td>
                        <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)} ({format_float(ticker.pricePercent * 100)} %)</td>
                        <TDTrend ticker={ticker} />
                        <td className="text-center">{ ticker.base }</td>
                        <td className="text-center">{ ticker.signal }</td>
                        <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
                        <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
                        <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{format_float(ticker.giaodichnn)}</td>
                        <td className="text-start">{ ticker.follow_note }</td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Follow;
