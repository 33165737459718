import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  map_industry,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import CTTService from '../services/CTTService';
import TDTicker from "../components/TDTicker";
import TDTrend from "../components/TDTrend";

function Margin() {
  const title = 'Margin';
  const [isLoading, setLoad] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    CTTService.get_margin_allowance_full().then(r => {
      setRows(r)
      setLoad(false)
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <thead>
                  <tr>
                    <th className="th-ticker">ticker</th>
                    <th className="th-industry">industry</th>
                    <th>date</th>
                    <th>priceEst</th>
                    <th>priceFlat</th>
                    <th>pricePercent</th>
                    <th>trend</th>
                    <th className="th-base">base</th>
                    <th className="th-signal">signal</th>
                    <th>priceAction</th>
                    <th>fs_rating</th>
                    <th>giaodichnn</th>
                  </tr>
                </thead>
                <tbody>
                {
                  rows && rows.map((ticker, i) => {
                    return (
                      <tr key={i}>
                        <TDTicker ticker={ticker}/>
                        <td className="text-start">{map_industry(ticker.industry)}</td>
                        <td className="text-end">{format_date(ticker.date)}</td>
                        <td className="text-end">{ticker.value_asset}</td>
                        <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)}</td>
                        <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_float(ticker.pricePercent * 100)} %</td>
                        <TDTrend ticker={ticker} />
                        <td className="text-center">{ ticker.base }</td>
                        <td className="text-center">{ ticker.signal }</td>
                        <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
                        <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
                        <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{format_float(ticker.giaodichnn)}</td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Margin;
