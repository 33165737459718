import {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_giaodichnn,
  format_price,
  format_rank,
  get_floor,
  has_margin, margin_remain,
} from '../common/util';
import CTTService from '../services/CTTService';
import FBOXService from '../services/FBOXService';
import LoadingSpinner from './LoadingSpinner';
import TickersIndustry from './TickersIndustry';

function ModalTickerDetail(props) {
  console.log(props);
  const [isLoading, setLoad] = useState(true);
  const [tickerData, setTickerData] = useState({});
  const [tenDays, setTenDays] = useState([]);
  const [industry, setIndustry] = useState(<></>);
  const [showValueBtnIndustry, setShowValueBtnIndustry] = useState(true);

  useEffect(() => {
    FBOXService.fetch_ticker_detail(props.ticker.ticker).then(r => {
      if (r.tickerData === null) {
        alert('Not found ' + props.ticker.ticker)
        return
      }
      let tickerData = JSON.parse(r.tickerData)
      calculate_value(tickerData.ticker, tickerData.noVCSH, tickerData.slcp).then(value => {
        tickerData['value_vcsh'] = `${value.value_vcsh.min} - ${value.value_vcsh.max}`
        tickerData['value_asset'] = `${value.value_asset.min} - ${value.value_asset.max}`
        setTickerData(tickerData)
        setTenDays(JSON.parse(r.tendays))
        setLoad(false)
      })
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  async function calculate_value(ticker, noVCSH, slcp) {
    const payload = [{
        'ticker': ticker,
        'no_vcsh': parseFloat(noVCSH),
        'slcp': slcp
      }]
    const res = await CTTService.calculate_value(payload)
    return res[ticker]
  }

  async function show_industry() {
    setShowValueBtnIndustry(false)
    setIndustry(<><TickersIndustry industry={tickerData.industry}/></>)
  }

  let margin_html = <span className={"no-margin"}></span>;
  if (has_margin(props.ticker.ticker)) {
    margin_html = <span className={"has-margin"}></span>
  }

  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      autoFocus={true}
      size={'lg'}
    >
      <Modal.Header>
        <h4>{margin_html}{tickerData.ticker}</h4>
        <sup>{get_floor(tickerData.ticker)}</sup>
        <span className={class_price_percent(tickerData.pricePercent)}>{tickerData.priceFlat} ({format_float(tickerData.pricePercent * 100)}%)</span>
        <span>{tickerData.company} ({tickerData.industry})</span>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="chart-tab" data-bs-toggle="tab" data-bs-target="#chart" type="button" role="tab" aria-controls="chart" aria-selected="false">chart</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="summary-tab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true">summary</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="signal-tab" data-bs-toggle="tab" data-bs-target="#signal" type="button" role="tab" aria-controls="signal" aria-selected="false">signal</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="industry-tab" data-bs-toggle="tab" data-bs-target="#industry" type="button" role="tab" aria-controls="industry" aria-selected="false">industry</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade" id="summary" role="tabpanel" aria-labelledby="summary-tab">
            <div className="table-responsive row">
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                    <tr>
                      <td className="ticker-modal-table text-start w-50">Định giá nhịp (VCSH)</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{tickerData.value_vcsh} </td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Định giá (Tổng tài sản)</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{tickerData.value_asset}</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Hạn mức margin</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{format_float(margin_remain(tickerData.ticker) / 1000000000)} tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Vốn hóa</td>
                      <td className="text-right-overview empty-black text-end col-md-6">{ tickerData.vonhoa.toLocaleString() } tỷ</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">EPS</td>
                      <td className="text-right-overview empty-black text-end">{ tickerData.eps_pha_loang }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">P/E</td>
                      <td className="text-right-overview empty-black text-end">{ tickerData.pe }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">ROE</td>
                      <td className="text-right-overview text-end"><span >{ format_float(tickerData.roe * 100) }%</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">TB KLGD</td>
                      <td className="text-right-overview empty-black text-end">{ tickerData.tb_klgd.toLocaleString() }</td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Giao dịch NN</td>
                      <td className="positive text-end"><span className={class_text_danger(tickerData.giaodichnn)}>{ tickerData.giaodichnn } tỷ</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">Nợ/VCSH</td>
                      <td className="text-end empty-black"><span >{ format_float(tickerData.noVCSH) }</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">+/- 5 phiên</td>
                      <td className="text-end"><span className={class_text_danger(tickerData.pricePercent5)}>{ format_float(tickerData.pricePercent5 * 100) }%</span></td>
                    </tr>
                    <tr>
                      <td className="ticker-modal-table text-start">+/- 60 phiên</td>
                      <td className="positive text-end"><span className={class_text_danger(tickerData.pricePercent60)}>{ format_float(tickerData.pricePercent60 * 100) }%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">SLCP</td>
                    <td className="text-right-overview empty-black text-end">
                      { tickerData.slcp.toLocaleString() }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Book value
                    </td>
                    <td className="text-right-overview empty-black text-end">
                      { tickerData.gia_tri_so_sach }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">P/B</td>
                    <td className="text-right-overview empty-black text-end">
                      { format_float(tickerData.pb) }
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">ROA</td>
                    <td className="text-end"><span >{ format_float(tickerData.roa * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">TB GTGD</td>
                    <td className="text-right-overview empty-black text-end">
                      { format_float(tickerData.tbgtdd, 2) } tỷ
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Sở Hữu NN</td>
                    <td className="negative text-end">
                      <span className={class_text_danger(tickerData.sohuungoai)}>{ format_float(tickerData.sohuungoai) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Biên lợi nhuận</td>
                    <td className="positive text-end">
                      <span className={class_text_danger(tickerData.bienloinhuan)}>{ format_float(tickerData.bienloinhuan * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">+/- 20 phiên</td>
                    <td className="text-end">
                      <span className={class_text_danger(tickerData.pricePercent20)}>{ format_float(tickerData.pricePercent20 * 100) }%</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Update BC</td>
                    <td className="text-end">
                      <span >{ tickerData.bctc }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="signal" role="tabpanel" aria-labelledby="signal-tab">
            <div className="table-responsive row">
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">Điểm nền tảng</td>
                    <td className="positive text-end"><span >{ format_float(tickerData.diem_tl) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">FS Rating</td>
                    <td className="text-right-overview empty-black text-end">
                      <span className={class_text_danger(tickerData.fs_rating, 60)}>{ tickerData.fs_rating }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">RS Rating</td>
                    <td className="text-right-overview empty-black text-end">
                      <span >{ tickerData.rs_rating }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.pullback_20 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Pullback 10</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.pullback_10 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 5</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.break5 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 20</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.break20 }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Breakout 60</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.break60 }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-bordered table-hover table-left ticker-modal-signal-table table-overview info-overview">
                  <tbody>
                  <tr>
                    <td className="ticker-modal-table text-start w-50">Điểm tín hiệu</td>
                    <td className=" positive text-end">
                      <span className={class_text_danger(tickerData.point)}>{ format_float(tickerData.point) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Top FS</td>
                    <td className="empty-black text-end">
                      <span className="align-left ">{ tickerData.fs_rating_rank }</span>
                      <span className="negative"><i className="mdi mdi-arrow-down"></i>
                            <span className=""> { format_rank(tickerData.ct_fs_rating_rank) }</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Top RS</td>
                    <td className="empty-black text-end">
                      <span className="align-left ">{ tickerData.rs_rating_rank }</span>
                      <span className="positive"><i className="mdi mdi-arrow-up"></i>
                            <span className=""> { format_rank(tickerData.ct_rs_rating_rank) }</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Giá Pullback</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.pullback_20_price }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Giá Pullback 10</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.pullback_10_price }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Tín hiệu chờ</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.signal }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Giá chờ</td>
                    <td className="signal large text-end">
                      <span>{ format_price(tickerData.wait_price) }</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="ticker-modal-table text-start">Điểm số chờ</td>
                    <td className="signal large text-end">
                      <span>{ tickerData.wait_score }</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="table table-bordered table-hover table-right ticker-modal-signal-table signal-table">
              <thead>
              <tr>
                <td colSpan="6">Tín hiệu 10 phiên trước</td>
              </tr>
              <tr className="header-table">
                <td>Ngày</td>
                <td>Giá</td>
                <td>+/-</td>
                <td>Signal</td>
                <td>Giá KN</td>
                <td>NN</td>
              </tr>
              </thead>
              <tbody>
              {
                tenDays && tenDays.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td className="align-center">{ format_date(row.date) }</td>
                      <td className="text-end">{ format_price(row.priceFlat) }</td>
                      <td className={"text-end" + class_price_percent(row.pricePercent)}>{ format_float(row.pricePercent * 100) } %</td>
                      <td className="normal align-center">{ row.signal }</td>
                      <td className="text-end">{ format_price(row.gia_thuc_hien) }</td>
                      <td className={"negative text-end" + class_text_danger(row.giaodichnn)}>{ format_giaodichnn(row.giaodichnn) }</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          <div className="tab-pane fade" id="industry" role="tabpanel" aria-labelledby="industry-tab">
            {industry}{showValueBtnIndustry && <button className="btn btn-outline-secondary btn-sm" onClick={() => show_industry()}>show</button>}
          </div>
          <div className="tab-pane fade show active" id="chart" role="tabpanel" aria-labelledby="chart-tab">
            <iframe src={`https://chart.finbox.vn/mobile-chart?popup=true&symbol=${tickerData.ticker}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} width={"100%"} height={"500px"}/>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

}

export default ModalTickerDetail;
