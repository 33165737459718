import axios from 'axios';


const CTTService = {

  async get_my_catalog() {
    let response = await axios.get('https://api.codetayto.com/api/securities/follow-tickers')
    return response.data
  },

  async get_total_assets(ticker) {
    let response = await axios.get(`https://api.codetayto.com/api/securities/${ticker}/financial`)
    return response.data.Value
  },

  async get_margin_allowance() {
    let response = await axios.get('https://api.codetayto.com/api/securities/margin-allowance')
    return response.data
  },

  async get_margin_allowance_full() {
    let response = await axios.get('https://api.codetayto.com/api/securities/margin-allowance-full')
    return response.data
  },

  async get_all_tickers() {
    let response = await axios.get('https://api.codetayto.com/api/securities/all-tickers')
    return response.data
  },

  async get_vn30() {
    let response = await axios.get('https://api.codetayto.com/api/securities/vn30')
    return response.data
  },

  async get_result_summary() {
    let response = await axios.get('https://api.codetayto.com/api/securities/result-summary')
    return response.data
  },

  async calculate_value(data) {
    let response = await axios.post('https://api.codetayto.com/api/securities/calculate-value', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data
  },

};

export default CTTService;
