import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';
import ModalRoot from '../components/ModalRoot';

import MainLayout from '../layouts/MainLayout';
import ConsoleLayout from '../layouts/ConsoleLayout';
import Home from '../pages/Home';
import Login from '../pages/Login';
import RecommendPS from '../pages/RecommendPS';
import Logout from '../pages/Logout';
import Rule from '../pages/Rule';
import Follow from '../pages/Follow';
import Signup from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Apps from '../pages/Apps';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import AuthProvider from '../hooks/AuthProvider';
import RecommendCS from '../pages/RecommendCS';
import Summary from '../pages/Summary';
import Margin from '../pages/Margin';
import History from '../pages/History';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="" element={<Home />} />
            <Route path="summary" element={<Summary />} />
            <Route path="recommend/cs" element={<RecommendCS />} />
            <Route path="recommend/ps" element={<RecommendPS />} />
            <Route path="rule" element={<Rule />} />
            <Route path="follow" element={<Follow />} />
            <Route path="margin" element={<Margin />} />
            <Route path="history" element={<History />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Route>
          <Route path="console" element={<ConsoleLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="apps" element={<Apps />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </AuthProvider>
      <ModalRoot />
    </BrowserRouter>
  );
}

export default App;
