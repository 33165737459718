import axios from 'axios';


const FBOXService = {
  async fetch_recommend_ps() {
    let response = await axios.post('https://derivative.finbox.vn/api/app/derivative/trend')
    return response.data.data
  },

  async fetch_recommend_cs(category) {
    let response = await axios.post('https://derivative.finbox.vn/api/app_new/tradingbox/all', { category: category })
    return response.data
  },

  async fetch_summary(day=0) {
    const body = {
      "day": day,
      "tables": [
        "overview",
        "buy_normal",
        "buy_standard",
        "sell_normal",
        "sell_standard",
        "wait_buy_normal",
        "wait_buy_standard",
        "wait_sell_normal",
        "wait_sell_standard",
        "basis_normal",
        "basis_standard",
        "fs_normal",
        "fs_standard",
        "volume_normal",
        "volume_standard",
        "foreign_buy",
        "foreign_sell",
        "break_up_normal_5",
        "break_up_standard_5",
        "break_down_normal_5",
        "break_down_standard_5",
        "price_up_normal",
        "price_up_standard",
        "price_down_normal",
        "price_down_standard",
        "sector_decrease",
        "sector_increase",
        "pullback_10_normal",
        "pullback_10_standard",
        "pullback_20_normal",
        "pullback_20_standard"
      ]
    }
    let response = await axios.post('https://api2.finbox.vn/api/app_new/getMarketData', body)
    return response.data
  },

  async fetch_ticker_detail(ticker) {
    let response = await axios.post('https://derivative.finbox.vn/api/app_new/getTickerDetail', {ticker: ticker, day: 0})
    return response.data
  },

  async fetch_tickers_detail(tickers) {
    let response = await axios.post('https://derivative.finbox.vn/api/app_new/getTickerDetailMultiple', tickers)
    return response.data
  },

  async fetch_tickers_industry(industry) {
    let response = await axios.post('https://derivative.finbox.vn/api/app_new/getTickerDetailMultipleByIndustry', {industry: industry, day: 'now'})
    return response.data
  },

};

export default FBOXService;
