import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  getPreviousWeekdays,
  isNullOrUndefined,
  json_parse,
  map_industry,
  merge_arrays,
  sortArrayByProperty,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import FBOXService from '../services/FBOXService';
import {format} from "date-fns";
import TDTicker from "../components/TDTicker";
import TDTrend from "../components/TDTrend";
import TDTickerVol from "../components/TDTickerVol";
import TDSignal from "../components/TDSignal";
import TDPrice from "../components/TDPrice";


function TableHead0() {
  return (
    <>
      <thead>
      <tr>
        <th>date</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th>trend</th>
        <th>fs_rating</th>
        <th>giaodichnn</th>
      </tr>
      </thead>
    </>
  )
}

export function TableHeadBase() {
  return (
    <>
      <thead>
      <tr>
        <th style={{width:"50%"}}>ticker</th>
        <th style={{width:"30%"}}>signal</th>
        <th style={{width:"20%"}}>price</th>
      </tr>
      </thead>
    </>
  )
}
function TableHead3() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
        <th className="th-base">base</th>
      </tr>
      </thead>
    </>
  )
}


function TableHead4() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
        <th>fs_rating</th>
        <th>fs_rating_rank</th>
      </tr>
      </thead>
    </>
  )
}


function TableHead5() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
        <th>volumeFlat</th>
        <th>volumePercent</th>
      </tr>
      </thead>
    </>
  )
}


function TableHead6() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
        <th>fs_rating</th>
        <th>giaodichnn</th>
      </tr>
      </thead>
    </>
  )
}

function TableHead7() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
        <th className="th-break5">break5</th>
      </tr>
      </thead>
    </>
  )
}

function TableHead8() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th className="th-signal">signal</th>
        <th>priceAction</th>
      </tr>
      </thead>
    </>
  )
}

function TableHead9() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th>fs_rating</th>
        <th>giaodichnn</th>
      </tr>
      </thead>
    </>
  )
}

function TableBody0({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <td className="text-end">{ format_date(ticker.date) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <TDTrend ticker={ticker} />
            <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
            <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

export function TableBodyBase({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTickerVol ticker={ticker}/>
            <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien}/>
            <TDPrice ticker={ticker}/>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBodyWait({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTickerVol ticker={ticker}/>
            <TDSignal signal={ticker.wait_signal} price={ticker.wait_price}/>
            <TDPrice ticker={ticker}/>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody3({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td>{ ticker.base }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody4({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
            <td>{ ticker.fs_rating_rank }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody5({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td className="text-end">{ ticker.volumeFlat }</td>
            <td className="text-end">{ format_float(ticker.volumePercent * 100) }%</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}


function TableBody6({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
            <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody7({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
            <td className="text-end">{ ticker.break5 }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody8({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTicker ticker={ticker}/>
            <td className="text-start">{map_industry(ticker.industry)}</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td>{ ticker.signal }</td>
            <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function TableBody9({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <td style={{minWidth: 100}}>{ map_industry(ticker.ticker) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
            <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
            <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
            <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn }</td>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}
function TableBodyPullback({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }
  return (
    <>
      <tbody>
      {
        rows.map((ticker, i) => (
          <tr key={ i }>
            <TDTickerVol ticker={ticker}/>
            <TDSignal signal={ticker.pullback} price={ticker.pullback_price}/>
            <TDPrice ticker={ticker}/>
          </tr>
        ))
      }
      </tbody>
    </>
  )
}

function Summary() {
  const title = 'SUMMARY';
  const tabs = [
    { target: 'trend' },
    { target: 'buy', active: 'active' },
    { target: 'sell' },
    { target: 'wait_buy' },
    { target: 'wait_sell' },
    { target: 'basis' },
    { target: 'cash_flow' },
    { target: 'volume' },
    { target: 'foreign' },
    { target: 'break' },
    { target: 'price' },
    { target: 'sector' },
  ];
  const [isLoading, setLoad] = useState(true);
  const [option, setOption] = useState(0);
  const [data, setData] = useState({});
  const [daySelect, setDaySelect] = useState([]);

  useEffect(() => {
    FBOXService.fetch_summary(option).then(response => {
      let temp = response
      temp.marketTwoData.buy = merge_arrays(
        json_parse(response.marketTwoData.buy_standard),
        json_parse(response.marketTwoData.buy_normal))
      temp.marketTwoData.buy = sortArrayByProperty(temp.marketTwoData.buy, 'industry')

      temp.marketTwoData.sell = merge_arrays(
        json_parse(response.marketTwoData.sell_standard),
        json_parse(response.marketTwoData.sell_normal))
      temp.marketTwoData.sell = sortArrayByProperty(temp.marketTwoData.sell, 'industry')

      temp.marketTwoData.wait_buy = merge_arrays(
        json_parse(response.marketTwoData.wait_buy_standard),
        json_parse(response.marketTwoData.wait_buy_normal))
      temp.marketTwoData.wait_buy = sortArrayByProperty(temp.marketTwoData.wait_buy, 'industry', true)

      temp.marketTwoData.wait_sell = merge_arrays(
        json_parse(response.marketTwoData.wait_sell_standard),
        json_parse(response.marketTwoData.wait_sell_normal))
      temp.marketTwoData.wait_sell = sortArrayByProperty(temp.marketTwoData.wait_sell, 'industry')

      temp.marketTwoData.basis = merge_arrays(
        json_parse(response.marketTwoData.basis_standard),
        json_parse(response.marketTwoData.basis_normal))
      temp.marketTwoData.basis = sortArrayByProperty(temp.marketTwoData.basis, 'pricePercent', true)

      temp.marketTwoData.cash_flow = merge_arrays(
        json_parse(response.marketTwoData.fs_standard),
        json_parse(response.marketTwoData.fs_normal))
      temp.marketTwoData.cash_flow = sortArrayByProperty(temp.marketTwoData.cash_flow, 'fs_rating', true)

      temp.marketTwoData.volume = merge_arrays(
        json_parse(response.marketTwoData.volume_standard),
        json_parse(response.marketTwoData.volume_normal))
      temp.marketTwoData.volume = sortArrayByProperty(temp.marketTwoData.volume, 'pricePercent', true)

      temp.marketTwoData.break_up = merge_arrays(
        json_parse(response.marketTwoData.break_up_standard_5),
        json_parse(response.marketTwoData.break_up_normal_5))
      temp.marketTwoData.break_up = sortArrayByProperty(temp.marketTwoData.break_up, 'pricePercent', true)

      temp.marketTwoData.break_down = merge_arrays(
        json_parse(response.marketTwoData.break_down_standard_5),
        json_parse(response.marketTwoData.break_down_normal_5))
      temp.marketTwoData.break_down = sortArrayByProperty(temp.marketTwoData.break_down, 'pricePercent')

      temp.marketTwoData.price_up = merge_arrays(
        json_parse(response.marketTwoData.price_up_standard),
        json_parse(response.marketTwoData.price_up_normal))
      temp.marketTwoData.price_up = sortArrayByProperty(temp.marketTwoData.price_up, 'pricePercent', true)

      temp.marketTwoData.price_down = merge_arrays(
        json_parse(response.marketTwoData.price_down_standard),
        json_parse(response.marketTwoData.price_down_normal))
      temp.marketTwoData.price_down = sortArrayByProperty(temp.marketTwoData.price_down, 'pricePercent')

      temp.marketTwoData.pullback = merge_arrays(
        json_parse(response.marketTwoData.pullback_10_standard),
        json_parse(response.marketTwoData.pullback_20_standard),
        json_parse(response.marketTwoData.pullback_10_normal),
        json_parse(response.marketTwoData.pullback_20_normal))
      temp.marketTwoData.pullback = sortArrayByProperty(temp.marketTwoData.pullback, 'industry', true)
      temp.marketTwoData.pullback = temp.marketTwoData.pullback.map(item => {
          if (!isNullOrUndefined(item['pullback_10']) && !isNullOrUndefined(item['pullback_20'])) {
            item['pullback'] = 'PULLBACK 10/20'
            item['pullback_price'] = `${format_price((item['pullback_10_price'] + item['pullback_20_price']) * 0.5)}`
          } else if (!isNullOrUndefined(item['pullback_10'])) {
            item['pullback'] = 'PULLBACK 10'
            item['pullback_price'] = format_price(item['pullback_10_price'])
          } else if (!isNullOrUndefined(item['pullback_20'])) {
            item['pullback'] = 'PULLBACK 20'
            item['pullback_price'] = format_price(item['pullback_20_price'])
          }
          return item
        })
      setData(temp)
      setLoad(false)
    });
    const daySelect = getPreviousWeekdays(new Date(), 6)
    setDaySelect(daySelect.map(item => {
      return format(item, 'EEEEEE dd/MM').toUpperCase();
    }))
  }, [option]);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  function changeOption(e) {
    console.log(e.target.value)
    setOption(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <select className="form-select form-select-sm mt-3" style={{margin:'auto', width:'auto'}} aria-label=".form-select-sm" onChange={(e) => changeOption(e)}>
          {
            daySelect.map((item, i) => (
              <option key={i} value={i}>{item}</option>
            ))
          }
        </select>
        <ul className="nav nav-pills" id="myTab" role="tablist">
          {
            tabs.map((item, i) => (
              <li key={i} className="nav-item" role="presentation">
                <button className={`nav-link ${item.active}`} id={`${item.target}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.target}`} type="button" role="tab" aria-controls={item.target} aria-selected="true">{item.target}</button>
              </li>
            ))
          }
        </ul>
        <div className="tab-content" id="myTabContent">

          <div className="tab-pane fade show" id="trend" role="tabpanel" aria-labelledby="trend-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <TableHead0 />
                <TableBody0 rows={json_parse(data.marketOneData.VNINDEX)} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show active" id="buy" role="tabpanel" aria-labelledby="buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <caption>BUY</caption>
                <TableHeadBase />
                <TableBodyBase rows={data.marketTwoData.buy} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>PULLBACK</caption>
                <TableHeadBase />
                <TableBodyPullback rows={data.marketTwoData.pullback} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="sell" role="tabpanel" aria-labelledby="sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyBase rows={data.marketTwoData.sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="wait_buy" role="tabpanel" aria-labelledby="wait_buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_buy} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="wait_sell" role="tabpanel" aria-labelledby="wait_sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="basis" role="tabpanel" aria-labelledby="basis-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead3 />
                <TableBody3 rows={data.marketTwoData.basis} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="cash_flow" role="tabpanel" aria-labelledby="cash_flow-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead4 />
                <TableBody4 rows={data.marketTwoData.cash_flow} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="volume" role="tabpanel" aria-labelledby="volume-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead5 />
                <TableBody5 rows={data.marketTwoData.volume} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="foreign" role="tabpanel" aria-labelledby="foreign-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>buy</caption>
                <TableHead6 />
                <TableBody6 rows={json_parse(data.marketTwoData.foreign_buy)} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>sell</caption>
                <TableHead6 />
                <TableBody6 rows={json_parse(data.marketTwoData.foreign_sell)} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="break" role="tabpanel" aria-labelledby="break-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHead7 />
                <TableBody7 rows={data.marketTwoData.break_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHead7 />
                <TableBody7 rows={data.marketTwoData.break_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="price" role="tabpanel" aria-labelledby="price-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHead8 />
                <TableBody8 rows={data.marketTwoData.price_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHead8 />
                <TableBody8 rows={data.marketTwoData.price_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show" id="sector" role="tabpanel" aria-labelledby="sector-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>increase</caption>
                <TableHead9 />
                <TableBody9 rows={json_parse(data.marketTwoData.sector_increase)} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>decrease</caption>
                <TableHead9 />
                <TableBody9 rows={json_parse(data.marketTwoData.sector_decrease)} />
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Summary;
