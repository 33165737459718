function TDSignal(
  {
    signal,
    price
  }
) {
  return (
    <td>
      {signal}
      <br/>
      <span className={"text-secondary"} style={{fontSize: "small"}}>{price}</span>
    </td>
  );
}

export default TDSignal;
