import {class_text_danger, get_floor, has_margin, is_own, is_vn30} from "../common/util";
import ModalService from "../services/ModalService";
import ModalTickerDetail from "./ModalTickerDetail";

function TDTicker(
  {
    ticker,
    popup = true,
    highlight_own = true
  }
) {
  const ticker_value = ticker.ticker;

  const cls_profit = ticker.profit !== undefined ? class_text_danger(ticker.profit) : '';
  const cls_own = highlight_own && is_own(ticker_value) ? 'bg-warning' : '';
  const v30_text = is_vn30(ticker_value) ? '*' : '';

  const more_classes = [cls_profit, cls_own].join(' ');

  let margin_html = <span className={"no-margin"}></span>;
  if (has_margin(ticker_value)) {
    margin_html = <span className={"has-margin"}></span>
  }

  const handleClick = () => {
    if (popup) {
      ModalService.open(ModalTickerDetail, {ticker});
    }
  };

  return (
    <td className={more_classes} style={{position: 'sticky'}} onClick={handleClick}>
        {margin_html}
        {ticker_value}
        <span className={"fw-bold"} style={{position: 'absolute', top: 0, right: 0, fontSize: 'xx-small'}}>{v30_text}</span>
        <span style={{position: 'absolute', bottom: 0, right: 0, fontSize: 'xx-small'}}>{get_floor(ticker_value)}</span>
    </td>
  );
}

export default TDTicker;
