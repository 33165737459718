import {useNavigate} from 'react-router-dom';

function Home() {
  const title = 'React Bootstrap Template';

  const navigate = useNavigate();

  return (
    <></>
  );
}

export default Home;
